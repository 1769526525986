import { Box, Heading, Text } from "@chakra-ui/react";

const Settings = () => {
  return (
    <Box>
      <Heading size="lg">Settings</Heading>
      <Text>This is where users can change their account settings.</Text>
    </Box>
  );
};

export default Settings;
